import React from "react"



const Contente05 = () => (<>

    <div className="m-1 p-3" >
        <h3 style={{
            textAlign: `center`
        }}>
            DURATA DEL PROGETTO
        </h3>
        <p>
            La durata del progetto era inizialmente di 30 mesi, con data di inizio il 1° luglio 2018. A seguito della situazione determinata dalla pandemia da Covid19 la durata del progetto è stata prorogata di 12 mesi e terminerà il prossimo 31 dicembre 2021.
        </p>
    </div>

</>)

export default Contente05