import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { Container, Row, Col } from "react-bootstrap"
import Img from "gatsby-image"

const ComponentImagePartner = () => {
  const data = useStaticQuery(graphql`
    {
        allFile(filter: {relativeDirectory: {eq: "projects/qohelet/biofeedstock/partner"}}, sort: {fields: name, order: ASC}) {
            edges {
              node {
                publicURL
                childImageSharp {
                  fixed(quality: 90, width: 200, height: 200) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
        }
    }
  `)
  return <div>
    <Container>
        <div>
            <Row className="vh-100 align-items-center text-center ">
                {
                    data.allFile.edges.map(({ node }, index) => {
                        return (
                        <Col key={index} sm={6} md={4} xl={3}>
                            <div>
                                <Img fixed={node.childImageSharp.fixed} />
                            </div>
                        </Col>
                        )
                    }
                )}
            </Row>
        </div>
    </Container>
  </div>
}


export default ComponentImagePartner