import React from "react"

const Contente03 = () => (<>

    <div className="m-1 p-3" >
        <h3 style={{
            textAlign: `center`
        }}>
            PARTENARIATO:
        </h3>
        <p>
            Università degli Studi di Napoli Federico II (Soggetto Capofila)
        </p>
        <p>
            Università degli Studi della Basilicata
        </p>
        <p>
            Università degli Studi della Tuscia
        </p>
        <p>
            Università degli Studi di Palermo
        </p>
        <p>
            Consorzio IPASS -Ingegneria per l’Ambiente e lo Sviluppo Sostenibile
        </p>
        <p>
            <a href="https://www.crdctecnologie.it/Progetto-BIOFEEDSTOCK">CRdC Tecnologie Scarl</a>
        </p>
        <p>
            <a href="https://www.eni.com/assets/documents/enirewind/acque-rifiuti/progetto-biofeedstock-enirewind.pdf">Eni S.p.A.</a>
        </p>
        <p>
            <a href="https://www.goriacqua.com/page/progetto-biofeedstock">GORI S.p.A. Gestione Ottimale Risorse Idriche</a>
        </p>
        <p>
            <a href="https://graded.it/portfolio_item/progetto-biofeedstock/">Graded Spa</a>
        </p>
        <p>
            Hydrolab
        </p>
        <p>
            <a href="https://archimede-srl.com/qohelet">Qohelet Solar Italia</a>
        </p>
        <p>
            Syndial Servizi Ambientali
        </p>
    </div>

</>)

export default Contente03