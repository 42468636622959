import React from "react"

import { Container } from "react-bootstrap"

import Header from "./00_header"

import Costi from "./01_costi"
import Abstract from "./00_abstract"
import Obiettivo from "./02_obiettivo"
import Partenariato from "./03_partenariato"
import Codici from "./04_codici"
import Durata from "./05_durata"
import Qsi_Obiettivi from "./06_qohelet_obiettivi"

import PonImage from "./image_pon"

import PartnersImage from "./images_partner"

import TargaImage from "./image_targa"

const IndexContentPage = () => (
  <>
    <Container>
        <br />
        <Header />
    </Container>

    <hr />

    <Container>

        <Codici />
    
        <Partenariato />

        <Abstract />

        <Obiettivo />

        <div className="m-1 p-3" >            
            <Costi />
        </div>


        <Durata />



        <Qsi_Obiettivi />
        
        <PartnersImage />

        <PonImage />

        <TargaImage />
    </Container>

  </>
)

export default IndexContentPage