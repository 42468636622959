import React from "react"

import { Row, Col } from "react-bootstrap"


const Contente00_h = () => (<>

    <Row className="h-100 justify-content-center" style={{
        textAlign: `center`
    }}>
        <Col xs="2" sm="3"></Col>
        <Col xs="8" sm="6">
            <h2 className="p-3">
                Programma: <a href="https://biofeedstock.it/">Biofeedstock</a>
            </h2>
        </Col>
        <Col xs="2" sm="3"></Col>
    </Row>
    
    <Row className="h-100 justify-content-md-center" style={{
        textAlign: `center`
    }}>
        <Col ></Col>
        <Col xs="10">
            <p className="lead">
            PROGETTO DI RICERCA: Biofeedstock - "Sviluppo di piattaforme tecnologiche integrate per la valorizzazione di biomasse residuali" (DIT.AD017.115)
            </p>
        </Col>
        <Col ></Col>
    </Row>

</>)

export default Contente00_h