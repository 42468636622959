import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { Container, Row, Col } from "react-bootstrap"
import Img from "gatsby-image"

const ComponentImagePon = () => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: {regex: "/projects/qohelet/biofeedstock/pon/"}) {
        publicURL
        childImageSharp {
            fluid(maxHeight: 200, quality: 90) {
                ...GatsbyImageSharpFluid
            }
        }
      }
    }
  `)
  return <div>
    <Container>
        <Row className="align-items-center text-center ">
            <Col></Col>
            <Col sm={12} md={12} xl={12}>
                <Img fluid={data.file.childImageSharp.fluid} alt="" />
            </Col>
            <Col></Col>
        </Row>
    </Container>
  </div>
}

/**
    {
      file(relativePath: {regex: "/projects/qohelet/biofeedstock/pon/"}) {
        publicURL
        childImageSharp {
          fixed(quality: 90, height: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
 */

// <Img fixed={data.file.childImageSharp.fixed} />

// data.file.childImageSharp.fluid
// {JSON.stringify(data, null, 4)}

export default ComponentImagePon