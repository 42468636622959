import React from "react"

import { Container, Row, Col } from "react-bootstrap"



const Contente01 = () => (<>
    <Container>
        <h3 style={{
            textAlign: `center`
        }}>
            COSTI
        </h3>
        <Row className="">
            <Col sm={12} md={12} xl={6}>
                <div className="m-1 p-3">
                    <h4>TOTALE PROGETTO</h4>
                    <p>
                        COSTI DEL PROGETTO: <b>8.753.407,62 €</b>
                    </p>
                    <div 
                    style={{
                        paddingLeft: '2rem'
                    }}
                    >
                        <table>
                            <tbody>
                                <tr>
                                    <td>DI CUI ATTIVITÀ RICERCA E SVILUPPO:</td>
                                    <td><b>6.313.767,23 €</b></td>
                                </tr>
                                <tr>
                                    <td>DI CUI ATTIVITÀ SVILUPPO SPERIMENTALE:</td>
                                    <td><b>2.439.640,39 €</b></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <p></p>
                    <p>
                        IMPORTO AGEVOLATO: <b>4.271.793,80 €</b>
                    </p> 
                    <div 
                    style={{
                        paddingLeft: '2rem'
                    }}
                    >
                        <table>
                            <tbody>
                                <tr>
                                    <td>DI CUI ATTIVITÀ RICERCA E SVILUPPO:</td>
                                    <td><b>3.156.883,61 €</b></td>
                                </tr>
                                <tr>
                                    <td>DI CUI ATTIVITÀ SVILUPPO SPERIMENTALE:</td>
                                    <td><b>1.114.910,19 €</b></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </Col>
            <Col sm={12} md={12} xl={6}>
                <div className="m-1 p-3">
                    <h4>QOHELT SOLAR ITALIA</h4>
                    <p>
                        COSTI DEL PROGETTO: <b>849.552,07 €</b>
                    </p>
                    <div 
                    style={{
                        paddingLeft: '2rem'
                    }}
                    >
                        <table>
                            <tbody>
                                <tr>
                                    <td>DI CUI ATTIVITÀ RICERCA E SVILUPPO:</td>
                                    <td><b>349.911,68 €</b></td>
                                </tr>
                                <tr>
                                    <td>DI CUI ATTIVITÀ SVILUPPO SPERIMENTALE:</td>
                                    <td><b>499.640,39 €</b></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <p></p>
                    <p>
                        IMPORTO AGEVOLATO: <b>424.776,03 €</b>
                    </p> 
                    <div 
                    style={{
                        paddingLeft: '2rem'
                    }}
                    >
                        <table>
                            <tbody>
                                <tr>
                                    <td>DI CUI ATTIVITÀ RICERCA E SVILUPPO:</td>
                                    <td><b>174.955,84 €</b></td>
                                </tr>
                                <tr>
                                    <td>DI CUI ATTIVITÀ SVILUPPO SPERIMENTALE:</td>
                                    <td><b>249.820,19 €</b></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </Col>
        </Row>
    </Container>
</>)

export default Contente01