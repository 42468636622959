import React from "react"

const Contente00 = () => (<>

    <div className="m-1 p-3" >
        <h3 style={{
            textAlign: `center`
        }}>
            ABSTRACT
        </h3>
        <p>
            Il Progetto di Ricerca BIOFEEDSTOCK "Sviluppo di Piattaforme Tecnologiche Integrate per la Valorizzazione di Biomasse Residuali" si colloca nell'ambito dell'Area di Specializzazione "Energia" in risposta all'avviso (D.D. n. 1735 del 13/07/2017) per la presentazione di progetti di ricerca e sviluppo sperimentale nelle 12 aree di specializzazione individuate dal Piano Nazionale della Ricerca 2015-2020 ("il Decreto").
        </p>
        <p>
            Il progetto si propone di contribuire alla più estesa valorizzazione delle biomasse residuali in processi di bioraffinazione finalizzati alla produzione di "biocombustibili sostenibili" e bio-chemicals attraverso l'integrazione di pretrattamenti in situ per la produzione di intermedi biogenici (biofeedstocks) e la loro valorizzazione finale in bioraffineria.
        </p>
        <p>
            Il progetto integra attività di studio, di ricerca sperimentale e di dimostrazione industriale per lo sviluppo di strumenti di supporto e per la realizzazione di percorsi dimostrativi.
        </p>
        <p>
            L'obiettivo del progetto è di contribuire all'abbattimento di barriere di tipo logistico e tecnologico per un più efficace inserimento delle biomasse residuali in schemi avanzati coerenti con i principi dell'economia circolare.
        </p>
    </div>

</>)

export default Contente00