import React from "react"

const Contente02 = () => (<>

    <div className="m-1 p-3" >
        <h3 style={{
            textAlign: `center`
        }}>
            OBIETTIVO
        </h3>

        <p>
            L'obiettivo del progetto BIOFEEDSTOCK è quello di contribuire all’abbattimento di barriere di tipo logistico e tecnologico (e talora di carattere culturale) per un più efficace inserimento delle biomasse residuali in schemi avanzati di trasformazione e valorizzazione coerenti con i principi dell’economia circolare.
        </p>
        <p>
            Il progetto integra attività di studio, di ricerca sperimentale e di dimostrazione industriale per lo sviluppo di strumenti di supporto e per la realizzazione di percorsi dimostrativi. I risultati del progetto saranno conoscenze, metodologie e know-how specifici sviluppati con riferimento a filiere di trasformazione selezionate tra quelle che offrono le più elevate potenzialità di remunerazione economica o una più estesa corrispondenza a requisiti di sostenibilità.
        </p>
        <p>
            La proposta progettuale è articolata in 7 (sette) Obiettivi Realizzativi costituiti da complessive 20 (venti) attività di ricerca (AR), distribuite tra Ricerca Industriale (RI) e Sviluppo Sperimentale (SS).
        </p>
    </div>

</>)

export default Contente02