import React from "react"



const Contente06 = () => (<>

    <div className="m-1 p-3" >
        <h3 style={{
            textAlign: `center`
        }}>
            QOHELET OBIETTIVI
        </h3>
        <h5>OR1 “VALUTAZIONE DEI BACINI DI POTENZIALE APPROVVIGIONAMENTO DI BIOMASSE RESIDUALI”</h5>
        <div className="pl-3 p-2">
            Attività svolta:
            <ul>
                <li>
                    Selezione delle filiere dimostrative:
                    <ul>
                        <li>
                            Siero del latte;
                        </li>
                        <li>
                            Acque di vegetazione oleifici
                        </li>
                        <li>
                            Pastazzo di arance.
                        </li>
                    </ul>
                </li>
            </ul>
        </div>

        <h5>OR2 “DEFINIZIONE DI LOGISTICHE DI APPROVVIGINAMENTO DI BIOMASSE RESIDUALI E DI PROCESSI DI PRETRATTAMENTO DELOCALIZZATO FUNZIONALI ALL’ALLUNGAMENTO DELLA FILIERA”</h5>
        <div className="pl-3 p-2">
            Attività svolta:
            <ul>
                <li>
                Stato dell’arte delle tecnologie di trattamento delle biomasse residuali;
                </li>
                <li>
                Accoppiamento di tecnologie di trattamento biomasse residuali con fonti energetiche rinnovabili;
                </li>
                <li>
                Proposta di un processo integrato con FER per il trattamento della FORSU;
                </li>
                <li>
                Pirolisi di uno scarto di digestato anaerobico;
                </li>
                <li>
                Proposta di impiego della tecnologia “SunGas” per la gasificazione di solidi e liquidi organici alimentata da energia solare;
                </li>
                <li>
                Proposta di impiego della tecnologia “pila termica” in un impianto basato su tecnologia “SunGas”
                </li>
            </ul>
        </div>
    
        <h5>OR3 “SVILUPPO DI UNA TECNOLOGIA DI OSSIDAZIONE/GASIFICAZIONE DI BIOMASSE RESIDUALI E BIOFEEDSTOCK IN ACQUA SUPERCRITICA”</h5>
        <div className="pl-3 p-2">
            Attività svolta:
            <ul>
                <li>
                Modellazione del processo di pirolisi lenta batch;
                </li>
                <li>
                Process Design Book di un impianto pilota di pirolisi batch biomasse residuali
                </li>
                <li>
                Progetto di uno stabilimento per la valorizzazione di scarti da FORSU o digestato anaerobico
                </li>
            </ul>
        </div>
    
        <h5>OR4 “VALUTAZIONE DEL POTENZIALE DI VALORIZZAZIONE DEI SOTTOPRODOTTI”</h5>
        <div className="pl-3 p-2">
            Attività svolta:
            <ul>
                <li>
                Valorizzazione di residui solidi
                </li>
                <li>
                    Valorizzazione di effluenti acquosi ad elevato carico organico
                    <ul>
                        <li>
                            Trattamento SCWO di effluenti ad elevato carico organico
                        </li>
                        <li>
                            Implementazione e simulazione dell’impianto di trattamento FORSU alimentato da F.E.R.
                        </li>
                        <li>
                            Tecnologia integrata SCWG/SCWO per il processamento di acque di vegetazione delle olive
                        </li>
                    </ul>
                </li>
            </ul>
        </div>

        <h5>OR7”ATTIVITA’ DI INTEGRAZIONE DI PROGETTO E DI DISSEMINAZIONE”</h5>
        <div className="pl-3 p-2">
            Attività svolta:
            <ul>
                <li>
                    Integrazione di progetto
                </li>
                <li>
                    Disseminazione
                </li>
            </ul>
        </div>

        

    </div>

</>)

export default Contente06