import React from "react"

import { Row, Col } from "react-bootstrap"


const Contente04 = () => (<>

    <Row className="h-100 justify-content-md-center" style={{
        textAlign: `left`
    }}>
        <Col xs="1" sm="2"></Col>
        <Col xs="10" sm="8">
            <table>
                <tbody>
                    <tr>
                        <td><b>CODICE PROGETTO</b></td>
                        <td>ARS01_00985</td>
                    </tr>
                    <tr>
                        <td><b>AREA TEMATICA</b></td>
                        <td>Ingegneria Ricerca & Sviluppo</td>
                    </tr>
                    <tr>
                        <td><b>RESPONSABILE TECNICO DI PROGETTO</b></td>
                        <td>Gianluca Tumminelli</td>
                    </tr>
                    <tr>
                        <td><b>RESPONSABILE AMMINISTRATIVO DI PROGETTO</b></td>
                        <td>Gaetano Tuzzolino</td>
                    </tr>
                </tbody>
            </table>
        </Col>
        <Col xs="1" sm="2"></Col>
    </Row>

</>)

export default Contente04