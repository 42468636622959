import React from "react"
import { graphql } from "gatsby"

import Layout from "../../../components/layouts/layout"
import SEO from "../../../components/seo"
import Header4 from "../../../components/contents/header4"

import Content1 from "../../../content/projects/qohelet/biofeedstock/content1"

//import { Container, Row, Col } from "react-bootstrap"
//import Img from "gatsby-image"
// import ContentPartener from "../../../content/projects/qohelet/biofeedstock/content_partner"

const ppp = ({ data, location }) => (
    <Layout
    header={
      <Header4
        src_sfondo={data.file.childImageSharp.fluid}
        title={data.site.siteMetadata.biofeedstock.title}
        link_page={data.site.siteMetadata.biofeedstock.link_page}
      />
    }
    location={location}
  >
    <SEO
      title={data.site.siteMetadata.biofeedstock.title}
      pathname={data.site.siteMetadata.biofeedstock.link_page}
    />

    <Content1></Content1>


  </Layout>

)


export const query = graphql`
  {
    file(relativePath: { regex: "/projects/qohelet/biofeedstock/header/" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    site {
      siteMetadata {
        biofeedstock {
          title
          seo_title
          link_page
        }
      }
    }
  }
`

export default ppp